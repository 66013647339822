/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    hr: "hr",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\n---\n<Frame\n\tstyle={{minHeight:400}}\n\theader={<div style={{ backgroundColor: 'black', height: 56 }} />}\n>\n\tPage Content\n</Frame>\n")), "\n", React.createElement(_components.h1, {
    id: "example-with-page--sidebar",
    style: {
      position: "relative"
    }
  }, "Example with Page & Sidebar", React.createElement(_components.a, {
    href: "#example-with-page--sidebar",
    "aria-label": "example with page  sidebar permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "scaled: true\n---\n<State initial={0}>\n\t{([active, setActive]) => (\n\t\t<Frame\n\t\t\tstyle={{minHeight:500}}\n\t\t\theader={<div style={{ backgroundColor: 'black', height: 56 }} />}\n\t\t>\n\t\t\t<Page\n\t\t\t\tsidebar={\n\t\t\t\t\t<Sidebar localStorageKey=\"frame__example-with-page-and-sidebar\">\n\t\t\t\t\t\t<SideNav title=\"Marketing\">\n\t\t\t\t\t\t\t<SideNav.Item onClick={() => setActive(0)} active={active===0}>Dashboard</SideNav.Item>\n\t\t\t\t\t\t\t<SideNav.Item onClick={() => setActive(1)} active={active===1}>Campaigns</SideNav.Item>\n\t\t\t\t\t\t\t<SideNav.Item onClick={() => setActive(2)} active={active===2}>Emails</SideNav.Item>\n\t\t\t\t\t\t\t<SideNav.Item onClick={() => setActive(3)} active={active===3}>Audiences</SideNav.Item>\n\t\t\t\t\t\t</SideNav>\n\t\t\t\t\t\t<Sidebar.Section>\n\t\t\t\t\t\t\t<Card onClick={() => setActive(4)} active={active===4}>Test</Card>\n\t\t\t\t\t\t</Sidebar.Section>\n\t\t\t\t\t</Sidebar>\n\t\t\t\t}\n\t\t\t>\n\t\t\t\t<Layout type=\"2Col\">\n\t\t\t\t\t<Layout.Section>\n\t\t\t\t\t\t<Card>Card 1</Card>\n\t\t\t\t\t</Layout.Section>\n\t\t\t\t\t<Layout.Section>\n\t\t\t\t\t\t<Card>Card 2</Card>\n\t\t\t\t\t</Layout.Section>\n\t\t\t\t</Layout>\n\t\t\t</Page>\n\t\t</Frame>\n\t)}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For the best experience when creating an application frame, use the following components:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/components/page"
  }, "Page")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/components/sidebar"
  }, "Sidebar")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
